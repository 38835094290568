import React, { Suspense, lazy } from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import { MapProvider } from './components/MapContext';

import ScreenTooSmallWarning from './components/mini/ScreenTooSmallWarning.js';
import Loader from './components/mini/Loader.js';
import Navigation from "./components/Navigation.js"

const Login = lazy(() => import('./components/pages/Login.js'));
const Meteo = lazy(() => import('./components/pages/Meteo.js'));
const ObvestiloForm = lazy(() => import('./components/pages/ObvestiloForm.js'));
const MeritevDozorevanjeList = lazy(() => import('./components/pages/MeritevDozorevanjeList.js'));
const MeritevDozorevanjeForm = lazy(() => import('./components/pages/MeritevDozorevanjeForm.js'));
const AnalizeRaziskave = lazy(() => import('./components/pages/AnalizeRaziskave.js'));
const NoviceDogodki = lazy(() => import('./components/pages/NoviceDogodki.js'));
const DozorevanjeGrozdja = lazy(() => import('./components/pages/DozorevanjeGrozdja.js'));
const ProstorskiOkoljski = lazy(() => import('./components/pages/ProstorskiOkoljski.js'));


function App() {

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          
          <Navigation />
          <Suspense fallback={<Loader />}>
            <div className="app-content">
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/dodaj-obvestilo" element={<ObvestiloForm />} />
                <Route exact path="/dozorevanje-grozdja/meritve" element={<MeritevDozorevanjeList />} />
                <Route exact path="/dozorevanje-grozdja/meritve/dodaj" element={<MeritevDozorevanjeForm />} />
                <Route exact path="/" element={<MapProvider><Meteo /></MapProvider>} />
                <Route exact path="/:id/*" element={<MapProvider><Meteo /></MapProvider>} />
                <Route exact path="/dozorevanje-grozdja" element={<MapProvider><DozorevanjeGrozdja /></MapProvider>} />
                <Route exact path="/dozorevanje-grozdja/:id/*" element={<MapProvider><DozorevanjeGrozdja /></MapProvider>} />
                <Route exact path="/prostorski-okoljski-podatki" element={<MapProvider><ProstorskiOkoljski /></MapProvider>} />
                <Route exact path="/analize-raziskave" element={<AnalizeRaziskave />} />
                <Route exact path="/novice-dogodki" element={<NoviceDogodki />} />
              </Routes>
            </div>
          </Suspense>
        </Router>
      </AuthProvider>
      <ScreenTooSmallWarning />
    </div>
  );
}

export default App;
