import React from 'react';

const ScreenTooSmallWarning = () => {
  return (
    <div className="screen-warning">
      <p>Aplikacija je dostopna samo na zaslonih širših od 1100 px.</p>
    </div>
  );
}

export default ScreenTooSmallWarning;