// AuthContext.js
import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie';


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get('_auth') != undefined || false);

  //console.log('auth provider -> is logged in? '+isLoggedIn);

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
