// MapContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const MapContext = createContext();

const MapProvider = ({ children }) => {
    const [coordinates, setCoordinates] = useState(() => {
        const storedCoordinates = localStorage.getItem('coordinates');
        return storedCoordinates ? JSON.parse(storedCoordinates) : { lat: 0, lng: 0 };
    });
    const [zoom, setZoom] = useState(() => {
        const storedZoom = localStorage.getItem('zoom');
        return storedZoom ? parseInt(storedZoom, 10) : 0;
    });

  const updateMapData = (newCoordinates, newZoom) => {
    setCoordinates(newCoordinates);
    setZoom(newZoom);
  };

  useEffect(() => {
    localStorage.setItem('coordinates', JSON.stringify(coordinates));
    localStorage.setItem('zoom', zoom.toString());
  }, [coordinates, zoom]);

  return (
    <MapContext.Provider value={{ coordinates, zoom, updateMapData }}>
      {children}
    </MapContext.Provider>
  );
};

const useMapContext = () => useContext(MapContext);

export { MapProvider, useMapContext };
