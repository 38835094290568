import React from 'react';
//import './Loader.css'; // Optional: Add your loader styling

const Loader = () => {
  return (
    <button type="button" className="bg-white-500 w-full text-xs p-2 text-gray-300" disabled>
        <svg className="animate-spin h-10 w-10 mt-6 m-auto" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="0" cy="0" r="0" stroke="white" strokeWidth="4"></circle>
            <path className="opacity-75" fill="#d1d5db" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <h4 className="pt-4">Nalagam...</h4>
    </button>
  );
}

export default Loader;